import React, { useEffect, useState, useContext, Fragment } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  IconButton,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Alert } from 'components';
import { APIClientContext, endpoints } from 'api';
import {
  ComplianceReportGenerateResponse,
  ComplianceReportItem,
  ComplianceReportListResponse,
} from 'types/ComplianceReport';
import { formatTimestamp, formatTimeago } from 'utils';

interface Props {
  siteId: string;
  deviceId: string;
}

export const ComplianceReportList = (props: Props) => {
  const { siteId, deviceId } = props;

  const apiClient = useContext(APIClientContext);
  const [allComplianceReports, setAllComplianceReports] = useState<ComplianceReportItem[]>([]);
  const [complianceReports, setComplianceReports] = useState<ComplianceReportItem[]>([]);
  const [limit, setLimit] = useState<number>(5);
  const [error, setError] = useState<string>('');
  const [refresh, setRefresh] = useState<boolean>(true);
  const [continuousRefresh, setContinuousRefresh] = useState<boolean>(false);
  const [generateDisabled, setGenerateDisabled] = useState<boolean>(false);

  const GENERATE_REFRESH_TIMEOUT = 15 * 1000; // refresh every 15s

  useEffect(() => {
    const fetchComplianceReports = async () => {
      console.log(
        'Refreshing compliance reports: refresh=%s continuousRefresh=%s',
        refresh,
        continuousRefresh
      );
      setRefresh(false);
      var scheduleRefresh = continuousRefresh;
      try {
        const response: ComplianceReportListResponse = (await apiClient.get({
          path: endpoints.site.complianceReports,
          ids: {
            siteId,
            deviceId,
          },
        })) as ComplianceReportListResponse;

        setError('');
        if (allComplianceReports.length !== response.reports.length) {
          console.log('Different reports');
          setAllComplianceReports(response.reports);
          if (continuousRefresh) {
            console.log('Stopping continuous refresh');
            setGenerateDisabled(false);
            setContinuousRefresh(false);
            scheduleRefresh = false;
          }
        }
      } catch (error) {
        console.error(error);
        setError(String(error));
      }

      if (scheduleRefresh) {
        console.log('Scheduling cont refresh');
        setTimeout(() => {
          setRefresh(true);
        }, GENERATE_REFRESH_TIMEOUT);
      }
    };

    if (refresh) {
      fetchComplianceReports();
    }
  }, [apiClient, siteId, deviceId, refresh, continuousRefresh, GENERATE_REFRESH_TIMEOUT]);

  useEffect(() => {
    const filterComplianceReports = async () => {
      const list: ComplianceReportItem[] = [];

      allComplianceReports.reverse().forEach((report) => {
        if (list.length < limit) {
          list.push(report);
        }
      });

      setComplianceReports(list);
    };

    filterComplianceReports();
  }, [allComplianceReports, limit]);

  async function handleGenerate() {
    try {
      setGenerateDisabled(true);

      const response: ComplianceReportGenerateResponse = (await apiClient.put({
        path: endpoints.site.generateComplianceReport,
        ids: {
          siteId,
          deviceId,
        },
      })) as ComplianceReportGenerateResponse;
      if (response.success) {
        setError('');
        setTimeout(() => {
          setContinuousRefresh(true);
          handleRefresh();
        }, GENERATE_REFRESH_TIMEOUT);
      } else {
        setError(response.error);
      }
    } catch (error) {
      console.error(error);
      setError(String(error));
      setGenerateDisabled(false);
    }
  }

  const handleRefresh = () => {
    setRefresh(true);
  };

  const handleLimit = (event: React.SyntheticEvent<{}>) => {
    const value = (event.target as HTMLInputElement).value;
    setLimit(Number(value));
  };

  const getComplianceReport = async (fileName: string) => {
    try {
      const response: ComplianceReportItem = (await apiClient.get({
        path: endpoints.site.getComplianceReport,
        ids: {
          siteId,
          deviceId,
          fileName,
        },
      })) as ComplianceReportItem;

      setError('');
      window.open(response.url);
    } catch (error) {
      console.error(error);
      setError(String(error));
    }
  };

  return (
    <Fragment>
      <Box display="flex" alignItems="center">
        <Typography variant="h5">Compliance Reports</Typography>
        <div className="flex-grow" />
        <Button onClick={handleGenerate} disabled={generateDisabled} variant="text">
          Generate
        </Button>
        <Select onChange={handleLimit} value={limit}>
          {[5, 10, 50, 100].map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
        <IconButton onClick={handleRefresh} edge="end">
          <RefreshIcon />
        </IconButton>
      </Box>
      {error && (
        <Box my={1}>
          {/* @ts-ignore */}
          <Alert variant="error" message={error} />
        </Box>
      )}
      <List>
        {complianceReports &&
          complianceReports.map((report: ComplianceReportItem) => {
            const date = new Date(report.report_date);
            return (
              <ListItem button key={report.name} onClick={() => getComplianceReport(report.name)}>
                <ListItemIcon>
                  <PictureAsPdfIcon />
                </ListItemIcon>
                <ListItemText primary={formatTimestamp(date)} secondary={formatTimeago(date)} />
              </ListItem>
            );
          })}
      </List>
    </Fragment>
  );
};
