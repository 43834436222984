import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText, Typography, Box } from '@material-ui/core';

// Subcomponent for individual list items
export const KeyValueListItem = (props) => {
  const { keyText, value, icon, onClick, secondaryText, ...rest } = props;

  return (
    <>
      <ListItem
        button={!!onClick}
        onClick={onClick}
        divider={false}
        style={{ padding: '0 10px' }}
        {...rest}
      >
        {/* Main Content */}
        <ListItemText
          primary={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" flexDirection="column">
                <Typography variant="body1" color="textPrimary" fontWeight="fontWeightMedium">
                  {keyText}
                </Typography>
                {secondaryText && (
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5 }}>
                    {secondaryText}
                  </Typography>
                )}
              </Box>
              <Box>
                <Typography variant="body1" color="textSecondary">
                  {value}
                </Typography>
              </Box>
            </Box>
          }
        />
      </ListItem>
    </>
  );
};

KeyValueListItem.propTypes = {
  keyText: PropTypes.string.isRequired,
  value: PropTypes.node,
  icon: PropTypes.node,
  showDivider: PropTypes.bool,
  onClick: PropTypes.func,
  secondaryText: PropTypes.string,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
};

KeyValueListItem.defaultProps = {
  value: '',
  showDivider: true,
};

export default KeyValueListItem;
